import { cva } from 'cva';
import React from 'react';

import { EDefaultCardV2Size } from '../DefaultCardV2.constants';

const commonFontClasses = 'text-base leading-mega-loose';
export const descriptionCVA = cva(
  'transition empty:hidden ' +
    'prose-a:text-brand-500 prose-a:hover:text-brand-550 ' +
    'prose-ol:ps-[1.5rem] prose-ol:pt-4 prose-ul:pt-4 ' +
    'prose-p:pt-4 prose-p:pe-2 prose-li:pb-2 last:prose-li:pb-0',
  {
    variants: {
      size: {
        [EDefaultCardV2Size.L]: commonFontClasses,
        [EDefaultCardV2Size.M]: commonFontClasses,
        [EDefaultCardV2Size.S]: 'text-sm leading-mega-loose xl:text-base',
        [EDefaultCardV2Size.Xs]: commonFontClasses,
      },
    },
  },
);

type TDescriptionProps = {
  description?: React.JSX.Element | string | null;
  className?: string;
};

export const Description: React.FC<TDescriptionProps> = ({
  description,
  className,
}) => {
  if (!description) {
    return null;
  }

  return <div className={className}>{description}</div>;
};
