import { cva } from 'cva';
import React from 'react';

import { ESimpleIconSize, Icon } from '../../../atoms/Icon';
import { ETitlePartsSize, TitlePart } from '../../../atoms/TitlePart';
import {
  EDefaultCardV2RightIconSize,
  EDefaultCardV2TitleSize,
} from '../DefaultCardV2.constants';

import type { ImageProps } from 'next/image';

const titleCva = cva('flex flex-row justify-between gap-4', {
  variants: {
    size: {
      [EDefaultCardV2TitleSize.Xl]:
        'md:text-4xl md:leading-relaxed xl:text-5xl xl:leading-loose',
      [EDefaultCardV2TitleSize.L]: 'md:text-4xl md:leading-relaxed',
      [EDefaultCardV2TitleSize.Ml]: '',
      [EDefaultCardV2TitleSize.M]:
        'xl:text-2xl xl:font-semibold xl:leading-extra-loose',
    },
  },
});

type TTitleProps = {
  title: React.JSX.Element | string | null;
  titleSize?: EDefaultCardV2TitleSize;
  rightIcon?: ImageProps | null;
  rightIconSize?: EDefaultCardV2RightIconSize;
  className?: string;
};

export const Title: React.FC<TTitleProps> = ({
  title,
  titleSize,
  rightIcon,
  rightIconSize,
  className,
}) => {
  if (!title) {
    return null;
  }

  return (
    <TitlePart
      tag='h3'
      size={
        titleSize === EDefaultCardV2TitleSize.M
          ? ETitlePartsSize.S
          : ETitlePartsSize.Ml
      }
      className={titleCva({ size: titleSize, className })}
    >
      <div className='[overflow-wrap:anywhere]'>{title}</div>

      {rightIcon && (
        <div className='shrink-0'>
          <Icon
            icon={rightIcon}
            size={
              rightIconSize === EDefaultCardV2RightIconSize.Xl
                ? ESimpleIconSize.Xl
                : ESimpleIconSize.L
            }
            className='mb-1 inline-block aspect-square object-center align-middle'
          />
        </div>
      )}
    </TitlePart>
  );
};
