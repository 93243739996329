import React from 'react';

import { EIconBottomPadding, ESimpleIconSize, Icon } from '../../../atoms/Icon';
import { EDefaultCardV2TopIconSize } from '../DefaultCardV2.constants';

import type { ImageProps } from 'next/image';

type TTopIconProps = {
  topIcon?: ImageProps | null;
  topIconSize?: EDefaultCardV2TopIconSize;
  bottomPadding?: EIconBottomPadding;
};

export const TopIcon: React.FC<TTopIconProps> = ({
  topIcon,
  topIconSize,
  bottomPadding,
}) => {
  if (!topIcon) {
    return null;
  }

  let size = ESimpleIconSize.Xl;
  if (topIconSize === EDefaultCardV2TopIconSize.Xl3) {
    size = ESimpleIconSize.XxxL;
  } else if (topIconSize === EDefaultCardV2TopIconSize.Xl2) {
    size = ESimpleIconSize.XxL;
  } else if (topIconSize === EDefaultCardV2TopIconSize.Xl1) {
    size = ESimpleIconSize.OneXl;
  }

  return <Icon icon={topIcon} bottomPadding={bottomPadding} size={size} />;
};
